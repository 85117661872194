import React, { useEffect, useState, useContext } from "react";
import { FaBook } from "react-icons/fa";
import { AiFillStar } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { MdAccessTime } from "react-icons/md";
import { useNavigate, useParams, Link } from "react-router-dom";
import { FaUserGraduate } from "react-icons/fa6";
import { GloblInfo } from "../App";
import { MdFavoriteBorder, MdOutlineFavorite } from "react-icons/md"; // Import both icons
import { FaRegBookmark } from "react-icons/fa";
import { FaBookmark } from "react-icons/fa6";

import { IoEyeSharp } from "react-icons/io5";

export const Courses = () => {
  const { userSession, setUserSession, userData } = useContext(GloblInfo);
  const [pdfCourses, setPdfCourses] = useState([]); // State to store PDF courses
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [page, setPage] = useState(1); // Current page for pagination
  const [hasMore, setHasMore] = useState(true); // To track if there are more PDFs to load
  const [savedPdfs, setSavedPdfs] = useState([]); // State to track saved PDFs
  const [saveCounts, setSaveCounts] = useState({}); // State to track save counts for each PDF

  const navigate = useNavigate(); // Initialize navigate
  // Fetch data from the API when the component is mounted or page changes
  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `http://localhost/notesmafia/get_uploads.php?page=${page}&limit=6`
        );

        // Check if the response is successful
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        // If we fetch less than 8 PDFs, it means there are no more PDFs to load
        if (result.length < 6) {
          setHasMore(false); // No more PDFs to load
        }

        // Avoid duplicate PDFs
        setPdfCourses((prevCourses) => {
          const newCourses = result.filter(
            (newItem) =>
              !prevCourses.some((prevItem) => prevItem.id === newItem.id)
          );
          return [...prevCourses, ...newCourses]; // Add only new items to the list
        });

        setLoading(false);
      } catch (err) {
        console.error("Error fetching data: ", err.message);
        setError("Failed to fetch data: " + err.message);
        setLoading(false);
      }
    };

    fetchCourses();
  }, [page]); // Re-run the effect when the page changes

  // Fetch saved PDFs for the logged-in user
  useEffect(() => {
    const fetchSavedPdfs = async () => {
      if (userData && userData.id) {
        const userId = userData.id;
        try {
          const response = await fetch(
            `http://localhost/notesmafia/save_pdf.php?user_id=${userId}`
          );
          const data = await response.json();
          setSavedPdfs(data.map((pdf) => pdf.id)); // Save the IDs of saved PDFs
        } catch (err) {
          console.error("Error fetching saved PDFs:", err);
        }
      }
    };

    fetchSavedPdfs();
  }, [userData]); // No conditional useEffect, check happens inside the effect

  // Fetch save count for a specific PDF
  useEffect(() => {
    pdfCourses.forEach((item) => {
      fetchSaveCount(item.id); // Trigger fetching the save count for each PDF by its ID
    });
  }, [pdfCourses]);
  const fetchSaveCount = async (pdfId) => {
    try {
      const response = await fetch(
        `http://localhost/notesmafia/save_pdf.php?pdf_id=${pdfId}`
      );
      const data = await response.json();

      // Log the response from the server for debugging
      console.log(`Save count for PDF ${pdfId}:`, data);

      // Check if save_count is returned from the API
      if (data.save_count !== undefined) {
        setSaveCounts((prevCounts) => ({
          ...prevCounts,
          [pdfId]: data.save_count, // Set the save count for the specific PDF
        }));
      } else {
        console.error(`Save count not found for PDF ${pdfId}`);
      }
    } catch (err) {
      console.error(`Error fetching save count for PDF ${pdfId}:`, err);
    }
  };

  const handleClick = async (item) => {
    try {
      // Increment the view count by calling the PHP API
      const response = await fetch(
        "http://localhost/notesmafia/update_views.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: item.id }), // Send the PDF ID to the backend
        }
      );

      const data = await response.json();

      if (data.message === "View count updated successfully") {
        // After updating the view count, navigate to the PDF detail page
        navigate(`/pdf/${item.id}`, {
          state: {
            pdfItem: item,
          },
        });
      } else {
        console.error("Error updating view count: ", data.message);
      }
    } catch (err) {
      console.error("Error updating view count: ", err);
    }
  };

  // Load more PDFs when "Show More" button is clicked
  const loadMore = () => {
    setPage((prevPage) => prevPage + 1); // Increment page number to fetch next set of PDFs
  };

  if (loading && page === 1) return <p>Loading courses...</p>; // Show loading only on initial load
  if (error) return <p>{error}</p>;
  console.log("pdf", pdfCourses);

  // Function to handle saving a PDF
  const handleSave = async (pdfId) => {
    const userId = userData.id;

    // Optimistic UI update
    setSavedPdfs((prevSaved) => [...prevSaved, pdfId]); // Immediately mark as saved
    setSaveCounts((prevCounts) => ({
      ...prevCounts,
      [pdfId]: (prevCounts[pdfId] || 0) + 1, // Increase save count immediately
    }));

    try {
      const response = await fetch("http://localhost/notesmafia/save_pdf.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: userId, pdf_id: pdfId }),
      });

      const data = await response.json();

      if (data.message !== "PDF saved successfully") {
        // Revert changes if saving failed
        setSavedPdfs((prevSaved) =>
          prevSaved.filter((savedPdfId) => savedPdfId !== pdfId)
        );
        setSaveCounts((prevCounts) => ({
          ...prevCounts,
          [pdfId]: (prevCounts[pdfId] || 1) - 1, // Revert save count
        }));
      }
    } catch (err) {
      console.error("Error saving PDF:", err);

      // Revert changes if an error occurs
      setSavedPdfs((prevSaved) =>
        prevSaved.filter((savedPdfId) => savedPdfId !== pdfId)
      );
      setSaveCounts((prevCounts) => ({
        ...prevCounts,
        [pdfId]: (prevCounts[pdfId] || 1) - 1,
      }));
    }
  };
  // Function to handle unsaving a PDF
  const handleUnsave = async (pdfId) => {
    const userId = userData.id;

    // Optimistic UI update
    setSavedPdfs((prevSaved) =>
      prevSaved.filter((savedPdfId) => savedPdfId !== pdfId)
    ); // Immediately mark as unsaved
    setSaveCounts((prevCounts) => ({
      ...prevCounts,
      [pdfId]: (prevCounts[pdfId] || 1) - 1, // Decrease save count immediately
    }));

    try {
      const response = await fetch("http://localhost/notesmafia/save_pdf.php", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: userId, pdf_id: pdfId }),
      });

      const data = await response.json();

      if (data.message !== "PDF unsaved successfully") {
        // Revert changes if unsaving failed
        setSavedPdfs((prevSaved) => [...prevSaved, pdfId]);
        setSaveCounts((prevCounts) => ({
          ...prevCounts,
          [pdfId]: (prevCounts[pdfId] || 0) + 1, // Revert save count
        }));
      }
    } catch (err) {
      console.error("Error unsaving PDF:", err);

      // Revert changes if an error occurs
      setSavedPdfs((prevSaved) => [...prevSaved, pdfId]);
      setSaveCounts((prevCounts) => ({
        ...prevCounts,
        [pdfId]: (prevCounts[pdfId] || 0) + 1,
      }));
    }
  };

  console.log("pdfCouer", pdfCourses);

  return (
    <>
      <section className="courses bg-[#F3F4F8] py-16">
        <div className="w-4/5 m-auto">
          <div className="heading mb-16">
            <h1 style={{ fontSize: "25px" }} className=" text-black">
              Find The Right <br />
              Online Notes/PDF/Old Peper For You
            </h1>
          </div>

          <div className="grid grid-cols-3 gap-8 md:grid-cols-1">
            {pdfCourses.map((item) => (
              <div
                key={item.id}
                // onClick={() => handleClick(item)} // Pass the entire item to handleClick
                className="box rounded-lg shadow-shadow1 bg-white"
              >
                <div className="images rounded-t-lg relative overflow-hidden  w-full">
                  <img
                    src={item.image_path} // Use the image path from the API
                    onClick={() => handleClick(item)} // Pass the entire item to handleClick
                    alt={item.title}
                    style={{ height: "250px" }}
                    className="rounded-t-lg object-cover w-full transition ease-in-out delay-150 cursor-pointer hover:scale-125 duration-300"
                  />
                  <div className="categ flex gap-4 absolute top-0 m-3">
                    <span className="text-[14px] bg-blue-700 p-1 px-3 text-white rounded-[5px] shadow-md">
                      {item.subject}
                    </span>
                    <span className="text-[14px] bg-pink-700 p-1 px-3 text-white rounded-[5px] shadow-md">
                      <h3
                        className="text-black"
                        style={{ fontSize: "15px", color: "white" }}
                      >
                        {item.course.includes("(")
                          ? item.course.match(/\(([^)]+)\)/)[1]
                          : item.course}
                      </h3>
                    </span>
                  </div>
                </div>
                <div className="text p-3">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <FaBook />
                      <span className="text-[14px] ml-2">{item.category}</span>
                    </div>
                    <div className="flex items-center">
                      <MdAccessTime className="text-orange-500" />
                      <span className="text-[14px] ml-2">
                        {item.created_at.split(" ")[0]}
                      </span>
                    </div>
                  </div>

                  <h3
                    className="text-black font-medium h-10"
                    style={{
                      marginTop: "10px",
                      color: "blue",
                      // marginBottom: "-10px",
                      fontSize: "23px",
                    }}
                  >
                    {item.title}
                  </h3>
                  <h3 className="text-black font-medium " style={{}}>
                    {item.description.split(" ").slice(0, 7).join(" ")}
                    {item.description.split(" ").length > 7 ? "..." : ""}
                  </h3>
                  <div style={{ marginBottom: "15px" }}>
                    <h3
                      className="text-black"
                      style={{ fontSize: "15px", height: "50px" }}
                    >
                      {item.college.split("(")[0].trim()}
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        gap: "30px",
                        marginTop: "10px",
                      }}
                    >
                      <span className="text-[14px] bg-blue-700 p-1 px-3 text-white rounded-[5px] shadow-md">
                        Semester : {item.semester}
                      </span>
                      <span className="text-[14px] bg-pink-700 p-1 px-3 text-white rounded-[5px] shadow-md">
                        year : {item.year}
                      </span>
                    </div>
                  </div>
                </div>
                {/* Save Icon for Saving PDF */}
                <div className="flex items-center justify-between border-t border-gray-200 p-3">
                  <div className="user flex items-center">
                    {/* <img
                      className="rounded-full"
                      src={`http://localhost/notesmafia/${item.profile_image}`}
                      alt={item.username}
                    /> */}
                    <FaUserGraduate />
                    <NavLink
                      to={`/profile/${item.username}`}
                      className="text-[14px] ml-2"
                      style={{ fontSize: "13px" }}
                    >
                      <span>{item.username}</span>
                    </NavLink>
                  </div>

                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <IoEyeSharp />
                    {item.views}
                  </span>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    {savedPdfs.includes(item.id) ? (
                      <FaBookmark
                        style={{ fontSize: "28px", color: "#007BFF" }}
                        className="cursor-pointer"
                        onClick={() => handleUnsave(item.id)} // Handle unsave on click
                      />
                    ) : (
                      <FaRegBookmark
                        style={{ fontSize: "28px" }}
                        className="cursor-pointer"
                        onClick={() => handleSave(item.id)} // Handle save on click
                      />
                    )}
                    <span>
                      {saveCounts[item.id] !== undefined
                        ? saveCounts[item.id]
                        : 0}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Show "Load More" button only if there are more PDFs to load */}
          {hasMore && (
            <div className="flex justify-center mt-10">
              <button
                onClick={loadMore}
                className="bg-blue-500 text-white p-2 px-4 rounded hover:bg-blue-700"
              >
                Show More
              </button>
            </div>
          )}
        </div>
      </section>
    </>
  );
};
