import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { GloblInfo } from "../../App";

const LoginSignUp = () => {
  const { userSession, setUserSession } = useContext(GloblInfo);
  const [isSignUpActive, setIsSignUpActive] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  // On page load, check if user session exists in localStorage
  useEffect(() => {
    const storedUserSession = localStorage.getItem("userSession");
    if (storedUserSession) {
      setUserSession(JSON.parse(storedUserSession)); // set state from localStorage
    }
  }, [setUserSession]); // Dependency array should include setUserSession

  const toggleForm = () => {
    setIsSignUpActive(!isSignUpActive);
    setErrorMessage(""); // Clear error message when toggling forms
  };

  // Handle Login
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("http://localhost/notesmafia/auth.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ login: true, email, password }),
      });

      const data = await response.json();

      if (data.status === "success") {
        // Set the user session in both global state and localStorage
        const userSessionData = {
          id: data.data.id,
          username: data.data.username,
          email: data.data.email,
          login: true,
        };
        setUserSession(userSessionData); // Set in global state
        localStorage.setItem("userSession", JSON.stringify(userSessionData)); // Set in localStorage

        alert("Login successful!");
        navigate("/");
      } else {
        alert("Login failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Handle Signup
  const handleSignup = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match!");
      return;
    }

    try {
      const response = await fetch("http://localhost/notesmafia/auth.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ signup: true, name, email, password }),
      });

      const data = await response.json(); // Parse JSON response
      if (data.status === "success") {
        setErrorMessage("");
        alert("Signup successful! You can now log in.");
        toggleForm(); // Switch to login form
      } else {
        setErrorMessage(data.message || "Signup failed");
      }
    } catch (error) {
      setErrorMessage("Error: " + error.message);
    }
  };

  // Handle Logout
  const handleLogout = async () => {
    try {
      const response = await fetch("http://localhost/notesmafia/auth.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ logout: true }),
      });
      const data = await response.json();

      if (data.status === "success") {
        // Remove user session from local storage and state
        localStorage.removeItem("userSession");
        setUserSession(null);

        alert("Logged out successfully!");
        navigate("/auth/login");
      } else {
        setErrorMessage("Logout failed");
      }
    } catch (error) {
      setErrorMessage("Error: " + error.message);
    }
  };

  return (
    <section className="auth_section">
      {userSession ? (
        // If logged in, show the profile page
        <div className="profile-container">
          <h2>Welcome, {userSession.username}</h2>
          <p>Email: {userSession.email}</p>
          <button onClick={handleLogout}>Logout</button>
        </div>
      ) : (
        // If not logged in, show the login/signup form
        <div className={`auth-container ${isSignUpActive ? "active" : ""}`}>
          {/* Sign In Form */}
          <div className="auth-user signin-box">
            <div className="image-box">
              <img
                src="https://raw.githubusercontent.com/WoojinFive/CSS_Playground/master/Responsive%20Login%20and%20Registration%20Form/img1.jpg"
                alt="Sign In Background"
              />
            </div>
            <div className="form-box">
              <form onSubmit={handleLogin}>
                <h2>Sign In</h2>
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <input type="submit" value="Login" />
                {errorMessage && <p className="error">{errorMessage}</p>}
                <p className="switch-text">
                  Don't have an account?
                  <span onClick={toggleForm}>Sign Up.</span>
                </p>
              </form>
            </div>
          </div>

          {/* Sign Up Form */}
          <div className="auth-user signup-box">
            <div className="form-box">
              <form onSubmit={handleSignup}>
                <h2>Create an account</h2>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <input
                  type="password"
                  name="password"
                  placeholder="Create Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <input type="submit" value="Sign Up" />
                {errorMessage && <p className="error">{errorMessage}</p>}
                <p className="switch-text">
                  Already have an account?
                  <span onClick={toggleForm}>Sign In.</span>
                </p>
              </form>
            </div>
            <div className="image-box">
              <img
                src="https://raw.githubusercontent.com/WoojinFive/CSS_Playground/master/Responsive%20Login%20and%20Registration%20Form/img2.jpg"
                alt="Sign Up Background"
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default LoginSignUp;
