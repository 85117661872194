import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { FaBook } from "react-icons/fa";
import { MdAccessTime } from "react-icons/md";
import { NavLink } from "react-router-dom";
import heroImg from "../components/assets/images/hero.png";
import heroImgback from "../components/assets/images/hero-shape-purple.png";
import { FaArrowDown } from "react-icons/fa";
import { FaUserGraduate } from "react-icons/fa";

import { BsFillLightningChargeFill } from "react-icons/bs";
import { FaBookReader, FaGraduationCap, FaUsers } from "react-icons/fa";
import { About } from "./About";
import { Courses } from "./Courses";
import { Instructor } from "./Instructor";
import { Blog } from "./Blog";

export const Home = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(
        `http://localhost/notesmafia/get_uploads.php?search=${searchQuery}&limit=8&page=1`
      );
      const data = await response.json();
      setResults(data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (item) => {
    console.log("Handle click for item: ", item);
    // Perform any action when the user clicks on the item (e.g., show more details)
  };

  return (
    <>
      <section className="bg-secondary py-10 h-[92vh] md:h-full">
        <div className="container">
          <div className="flex items-center justify-center md:flex-col">
            <div className="left w-1/2 text-black md:w-full">
              <h1 className="text-4xl leading-tight text-black font-semibold">
                Launch your <br /> Own online learning <br /> Platform
              </h1>
              <h3 className="text-lg mt-3" style={{ textAlign: "center" }}>
                Unlimited access to all 60+ instructors.
              </h3>
              {/* <span className="text-[14px]">
                <span> 2 passes (with access to all classes) for $240</span>
              </span> */}

              <form onSubmit={handleSearchSubmit} className="relative mt-5">
                <input
                  style={{ width: "100%" }}
                  type="search"
                  className="py-3 text-sm bg-white rounded-md pl-10 focus:outline-none"
                  placeholder="Search..."
                  autoComplete="off"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <button
                    type="submit"
                    className="p-1 focus:outline-none focus:shadow-outline"
                  >
                    <FiSearch />
                  </button>
                </span>
              </form>
              {/* <span className="text-[14px]">
                You`re guaranteed to find something that`s right for you.
              </span> */}
            </div>
            <div className="right w-1/2 md:w-full relative">
              <div className="images relative">
                <img
                  src={heroImgback}
                  alt="Background Shape"
                  className="absolute top-32 left-10 w-96 md:left-10"
                />
                <div className="img h-[85vh] w-full">
                  <img
                    src={heroImg}
                    alt="Hero Illustration"
                    className="h-full w-full object-contain z-20 relative"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Search Results Section */}
      <section className="container mx-auto">
        {loading && <p>Loading results...</p>}
        {!loading && results.length === 0 && (
          <div
            style={{
              display: "none",
              justifyContent: "space-around",
              height: "",
            }}
          ></div>
        )}
        {!loading && results.length > 0 && (
          <div>
            <h2
              style={{
                textAlign: "center",
                marginBottom: "40px",
                fontSize: "20px",
              }}
            >
              Your Search Rsesults
            </h2>
            <div className="grid grid-cols-3 gap-8 md:grid-cols-1">
              {results.map((item) => (
                <div
                  key={item.id}
                  className="box rounded-lg shadow-shadow1 bg-white"
                >
                  <div className="images rounded-t-lg relative overflow-hidden w-full">
                    <img
                      src={item.image_path} // Use the image path from the API
                      onClick={() => handleClick(item)} // Pass the entire item to handleClick
                      alt={item.title}
                      style={{ height: "250px" }}
                      className="rounded-t-lg object-cover w-full transition ease-in-out delay-150 cursor-pointer hover:scale-125 duration-300"
                    />
                    <div className="categ flex gap-4 absolute top-0 m-3">
                      <span className="text-[14px] bg-blue-700 p-1 px-3 text-white rounded-[5px] shadow-md">
                        {item.subject}
                      </span>
                      <span className="text-[14px] bg-pink-700 p-1 px-3 text-white rounded-[5px] shadow-md">
                        {item.course.includes("(")
                          ? item.course.match(/\(([^)]+)\)/)[1]
                          : item.course}
                      </span>
                    </div>
                  </div>
                  <div className="text p-3">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <FaBook />
                        <span className="text-[14px] ml-2">
                          {item.category}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <MdAccessTime className="text-orange-500" />
                        <span className="text-[14px] ml-2">
                          {item.created_at.split(" ")[0]}
                        </span>
                      </div>
                    </div>

                    <h3
                      className="text-black font-medium h-10"
                      style={{
                        marginTop: "10px",
                        color: "blue",
                        fontSize: "23px",
                      }}
                    >
                      {item.title}
                    </h3>
                    <h3 className="text-black font-medium">
                      {item.description.split(" ").slice(0, 7).join(" ")}
                      {item.description.split(" ").length > 7 ? "..." : ""}
                    </h3>
                    <div style={{ marginBottom: "15px" }}>
                      <h3 className="text-black" style={{ fontSize: "15px" }}>
                        {item.college.split("(")[0].trim()}
                      </h3>
                      <div
                        style={{
                          display: "flex",
                          gap: "30px",
                          marginTop: "10px",
                        }}
                      >
                        <span className="text-[14px] bg-blue-700 p-1 px-3 text-white rounded-[5px] shadow-md">
                          Semester : {item.semester}
                        </span>
                        <span className="text-[14px] bg-pink-700 p-1 px-3 text-white rounded-[5px] shadow-md">
                          Year : {item.year}
                        </span>
                      </div>
                    </div>

                    <div className="user flex items-center">
                      <FaUserGraduate />
                      <NavLink
                        to={`/profile/${item.username}`}
                        className="text-[14px] ml-2"
                        style={{ fontSize: "13px" }}
                      >
                        <span>{item.username}</span>
                      </NavLink>
                    </div>
                  </div>
                  <div className="flex items-center justify-between border-t border-gray-200 p-3">
                    <span className="text-sm text-primary">Free</span>
                    <span onClick={() => handleClick(item)}></span>
                    Know Details
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </section>
      <Courses />
      <br />
      <br />
      <br />
      <About />
      <Instructor />
      <Blog />
    </>
  );
};
