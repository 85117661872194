import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GloblInfo } from "../../App";
import "./editProfile.css";

const EditProfile = () => {
  const { userSession, setUserSession } = useContext(GloblInfo);
  const [user, setUser] = useState(null); // Store the user data
  const [message, setMessage] = useState("");
  const [previewImage, setPreviewImage] = useState("img/user.jpg"); // Default profile picture
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const storedUser = localStorage.getItem("userSession");
      if (storedUser) {
        const loggedInUser = JSON.parse(storedUser);

        // Fetch all users from the API
        const response = await fetch(
          `http://localhost/notesmafia/get_users.php`
        );
        const result = await response.json();

        if (result.status === "success") {
          const users = result.data;
          // Find the user whose ID matches the logged-in user
          const matchedUser = users.find((user) => user.id === loggedInUser.id);

          if (matchedUser) {
            setUser(matchedUser);
            setPreviewImage(matchedUser.profile_image || "img/user.jpg");
          } else {
            setMessage("User not found");
          }
        } else {
          setMessage("Failed to retrieve user data");
        }
      }
    };

    fetchUserData();
  }, []);
  console.log(user);

  const handleProfileUpdate = () => {
    const formData = new FormData();

    // Fallback to current values or default values if fields are empty
    formData.append("id", user.id);
    formData.append("username", user.username || ""); // Ensure it has a value
    formData.append("email", user.email || ""); // Ensure it has a value
    formData.append("name", user.name || ""); // Ensure it has a value
    formData.append("bio", user.bio || ""); // Fallback to empty string if no bio
    formData.append("gender", user.gender || ""); // Fallback to empty string
    formData.append("profession", user.profession || ""); // Ensure it has a value
    formData.append("link_1", user.link_1 || ""); // Fallback to empty string
    formData.append("link_2", user.link_2 || ""); // Fallback to empty string
    formData.append("number", user.number || ""); // Fallback to empty string
    formData.append("state", user.state || ""); // Ensure it has a value
    formData.append("city", user.city || ""); // Ensure it has a value
    formData.append("profilePicture", user.profilePicture || ""); // Handle profile picture

    fetch("http://localhost/notesmafia/update_profile.php", {
      method: "POST",
      body: formData, // Send form data including file
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setMessage("Profile updated successfully!");
          setUserSession(user); // Update global session data
          localStorage.setItem("userSession", JSON.stringify(user));
          navigate(`/profile/${user.username}`);
        } else {
          setMessage(data.message || "Failed to update profile");
        }
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
        setMessage(
          `An error occurred while updating the profile: ${error.message}`
        );
      });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUser({ ...user, profilePicture: file });
      setPreviewImage(URL.createObjectURL(file)); // Set image preview for file
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="edit-profile-wrapper">
      <h1>Edit Profile</h1>
      <div className="profile-update-form">
        <div className="profile_photo">
          <img
            src={`http://localhost/notesmafia/${
              user.profile_image || "img/user.jpg"
            }`}
            alt="Profile"
            style={{
              width: "150px",
              height: "150px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
          <span>
            <h1>{user.username}</h1>
            <h1>{user.email}</h1>
          </span>
        </div>
        {/* <input
          type="text"
          placeholder={user.username || "Username"}
          value={user.username}
          disabled
        /> */}
        {/* <input
          type="email"
          placeholder={user.email || "Email"}
          value={user.email}
          disabled
        /> */}
        <input
          type="text"
          placeholder="Name"
          value={user.name || "ss"}
          onChange={(e) => setUser({ ...user, name: e.target.value })}
        />
        <textarea
          placeholder="Enter your bio..."
          value={user.bio || ""} // Check if bio is null
          onChange={(e) => setUser({ ...user, bio: e.target.value })}
        ></textarea>

        <div className="form-group">
          <select
            id="gender"
            value={user?.gender || ""}
            onChange={(e) => setUser({ ...user, gender: e.target.value })}
          >
            <option value="">Select Gender</option> {/* Default placeholder */}
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <input
          type="text"
          placeholder="Profession"
          value={user?.profession || ""}
          onChange={(e) => setUser({ ...user, profession: e.target.value })}
        />
        <input
          type="text"
          placeholder="Link 1"
          value={user?.link_1 || ""}
          onChange={(e) => setUser({ ...user, link_1: e.target.value })}
        />
        <input
          type="text"
          placeholder="Link 2"
          value={user?.link_2 || ""}
          onChange={(e) => setUser({ ...user, link_2: e.target.value })}
        />
        <input
          type="text"
          placeholder="Phone Number"
          value={user?.number || ""}
          onChange={(e) => setUser({ ...user, number: e.target.value })}
        />
        <input
          type="text"
          placeholder="State"
          value={user?.state || ""}
          onChange={(e) => setUser({ ...user, state: e.target.value })}
        />
        <input
          type="text"
          placeholder="City"
          value={user?.city || ""}
          onChange={(e) => setUser({ ...user, city: e.target.value })}
        />
        <input type="file" onChange={handleImageChange} />
        <button onClick={handleProfileUpdate}>Save Changes</button>
        {message && <p className="message">{message}</p>}
      </div>
    </div>
  );
};

export default EditProfile;
