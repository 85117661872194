import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import { Bars } from "react-loader-spinner";
import InfoTextCard from "../../components/common/InfoCard/InfoCard";
import allCoursesData from "../../MasterCourse/Course.json"; // Assuming you have a JSON file for courses
// import "./FileUpload.css"; // Reuse the styles from FileUpload.css
import { GloblInfo } from "../../App"; // Assuming you're using global context for user session

const EditPdf = () => {
  const location = useLocation(); // Get the passed state from navigation
  const navigate = useNavigate();
  const { userSession } = useContext(GloblInfo); // Global context for session

  const pdfData = location.state?.pdfItem || {}; // Get PDF data from the state
  console.log("pdfData", pdfData);

  // State for form data
  const [formData, setFormData] = useState({
    title: pdfData.title || "",
    description: pdfData.description || "",
    subject: pdfData.subject || "",
    course: pdfData.course || "",
    semester: pdfData.semester || "",
    year: pdfData.year || "",
    category: pdfData.category || "",
    tags: pdfData.tags ? pdfData.tags.split(",") : [],
    college: pdfData.college || "",
  });

  const [tagInput, setTagInput] = useState("");
  const [collegeList, setCollegeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);

  // Handle form field changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Fetch College/University List based on search input
  const fetchCollegeList = async (name) => {
    try {
      const response = await fetch(
        `http://localhost/notesmafia/college_name.php?college_name=${name}&limit=15`
      );
      const data = await response.json();
      setCollegeList(data);
    } catch (error) {
      console.error("Error fetching college list:", error);
    }
  };

  // Handle search input change for College/University
  const handleCollegeInputChange = (e) => {
    setFormData({ ...formData, college: e.target.value });
    if (e.target.value) {
      fetchCollegeList(e.target.value);
    } else {
      setCollegeList([]);
    }
  };

  // Handle selecting a College/University from the dropdown
  const handleCollegeSelect = (college) => {
    setFormData({ ...formData, college: college.College_Name });
    setCollegeList([]);
  };

  // Add a tag (max 5 tags)
  const handleAddTag = () => {
    if (tagInput && formData.tags.length < 5) {
      setFormData({ ...formData, tags: [...formData.tags, tagInput] });
      setTagInput("");
    }
  };

  // Remove a tag
  const handleRemoveTag = (tagToRemove) =>
    setFormData({
      ...formData,
      tags: formData.tags.filter((tag) => tag !== tagToRemove),
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Updating PDF with ID:", pdfData.id);

    try {
      const response = await fetch(
        "http://localhost/notesmafia/update_pdf.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
            pdf_id: pdfData.id, // Ensure PDF ID is sent
            username: pdfData.username, // Ensure Username is sent
          }),
        }
      );

      // Check for proper JSON response
      const data = await response.json();

      if (data.message === "PDF updated successfully") {
        alert("PDF updated successfully");
        navigate(`/pdf/${pdfData.id}`);
      } else {
        alert("Failed to update PDF: " + data.message);
      }
    } catch (error) {
      console.error("Error updating PDF:", error);
    }
  };

  return (
    <div className="file-upload-container">
      <div className="file-upload-card">
        <h3>Edit PDF</h3>

        <form onSubmit={handleSubmit}>
          <div className="form-input-section">
            {/* Title Input */}
            <div className="form-input-group">
              <label htmlFor="fileTitleInput">File Title</label>
              <input
                type="text"
                id="fileTitleInput"
                name="title"
                placeholder="File Title"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </div>

            {/* Description Input */}
            <div className="form-input-group">
              <label htmlFor="fileDescriptionInput">File Description</label>
              <textarea
                id="fileDescriptionInput"
                name="description"
                placeholder="File Description"
                value={formData.description}
                onChange={handleChange}
                required
              />
            </div>

            {/* College/University Input */}
            <div className="form-input-group">
              <label htmlFor="collegeSearchInput">
                Select College/University
              </label>
              <input
                type="text"
                id="collegeSearchInput"
                placeholder="Type College Name"
                value={formData.college}
                onChange={handleCollegeInputChange}
                required
              />
              {collegeList.length > 0 && (
                <ul className="college-dropdown">
                  {collegeList.map((college, index) => (
                    <li
                      key={index}
                      onClick={() => handleCollegeSelect(college)}
                    >
                      {college.College_Name}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Course Input */}
            <div className="form-input-group">
              <label htmlFor="courseSelect">Select Course</label>
              <select
                id="courseSelect"
                name="course"
                value={formData.course}
                onChange={handleChange}
                required
              >
                <option value="">Select Course</option>
                {allCoursesData.map((course, index) => (
                  <option key={index} value={course.course}>
                    {course.course}
                  </option>
                ))}
              </select>
            </div>

            {/* Subject Input */}
            <div className="form-input-group">
              <label htmlFor="subjectSelect">Select Subject</label>
              <select
                id="subjectSelect"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
              >
                <option value="">Select Subject</option>
                {formData.course &&
                  allCoursesData
                    .find((course) => course.course === formData.course)
                    .subjects.map((subject, index) => (
                      <option key={index} value={subject}>
                        {subject}
                      </option>
                    ))}
              </select>
            </div>

            {/* Year Input */}
            <div className="form-input-group">
              <label htmlFor="yearSelect">Select Year</label>
              <select
                id="yearSelect"
                name="year"
                value={formData.year}
                onChange={handleChange}
                required
              >
                <option value="">Select Year</option>
                <option value="1st">1st Year</option>
                <option value="2nd">2nd Year</option>
                <option value="3rd">3rd Year</option>
                <option value="4th">4th Year</option>
              </select>
            </div>

            {/* Semester Input */}
            <div className="form-input-group">
              <label htmlFor="semesterSelect">Select Semester</label>
              <select
                id="semesterSelect"
                name="semester"
                value={formData.semester}
                onChange={handleChange}
                required
              >
                <option value="">Select Semester</option>
                <option value="1st">1st Semester</option>
                <option value="2nd">2nd Semester</option>
                <option value="3rd">3rd Semester</option>
                <option value="4th">4th Semester</option>
                <option value="5th">5th Semester</option>
                <option value="6th">6th Semester</option>
                <option value="7th">7th Semester</option>
                <option value="8th">8th Semester</option>
              </select>
            </div>

            {/* Category Input */}
            <div className="form-input-group">
              <label htmlFor="categorySelect">Select Category</label>
              <select
                id="categorySelect"
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
              >
                <option value="">Select Category</option>
                <option value="Notes">Notes</option>
                <option value="Old_Paper">Old Paper</option>
              </select>
            </div>

            {/* Tags Input */}
            <div className="form-input-group tag-input-group">
              <label htmlFor="tagInput">Add Tag (Max 5)</label>
              <InfoTextCard
                tags={formData.tags}
                onRemoveTag={handleRemoveTag}
              />
              <span style={{ display: "flex", gap: "20px" }}>
                <input
                  type="text"
                  value={tagInput}
                  onChange={(e) => setTagInput(e.target.value)}
                  placeholder="Enter tag"
                />
                <button
                  className="upload-btn"
                  type="button"
                  onClick={handleAddTag}
                >
                  Add
                </button>
              </span>
            </div>

            {/* Progress Bar and Update Button */}
            <div className="form-input-group tag-input-group">
              <ProgressBar
                completed={progressPercent}
                height="15px"
                bgColor="#005af0"
              />
              <button className="upload-btn" type="submit" disabled={loading}>
                {loading ? "Updating..." : "Update PDF"}
              </button>
            </div>
          </div>
        </form>

        {loading && (
          <div className="loading-bar">
            <Bars color="#005af0" />
          </div>
        )}
      </div>
    </div>
  );
};

export default EditPdf;
