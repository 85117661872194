import React from "react";
import { useLocation } from "react-router-dom";
import "./PdfDetails.css"; // Import your custom CSS

const PdfDetails = () => {
  const location = useLocation();
  const { pdfItem } = location.state || {}; // Get passed PDF item from state

  // Handle case when pdfItem is not available
  if (!pdfItem) {
    return <div className="no-data">No PDF data available</div>;
  }

  return (
    <div className="pdf-details-container">
      <h1 className="pdf-title">{pdfItem.title}</h1>

      <div className="pdf-metadata">
        <p className="pdf-info">
          <strong>Subject:</strong> {pdfItem.subject}
        </p>
        <p className="pdf-info">
          <strong>Year:</strong> {pdfItem.year}
        </p>
      </div>

      <p className="pdf-description">{pdfItem.description}</p>

      <iframe
        className="pdf-iframe"
        src={`http://localhost/notesmafia/${pdfItem.file_path}`}
        width="100%"
        height="600px"
        title={pdfItem.title}
      ></iframe>
    </div>
  );
};

export default PdfDetails;
