import React, { useState, createContext, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "./components/common/Layout";
import { Home } from "./pages/Home";
import { BlogSinglePage } from "./components/common/BlogSinglePage";
import { About } from "./pages/About";
import { Courses } from "./pages/Courses";
import { Blog } from "./pages/Blog";
import { Instructor } from "./pages/Instructor";
import LoginSignUp from "./pages/auth/LoginSignUp";
import Profile from "./pages/profile/Profile";
import EditProfile from "./pages/profile/EditProfile";
import { UserProvider } from "./context/UserContext";
import FileUpload from "./pages/FileUpload/FileUpload";
import PdfDetails from "./components/common/PdfDetails/PdfDetails";
import EditPdf from "./pages/EditPdf/EditPdf";

export const GloblInfo = createContext();

function App() {
  const [userSession, setUserSession] = useState(null);
  const [uploads, setUploads] = useState([]); // State to store data
  const [userData, setUserData] = useState(null);
  const [previewImage, setPreviewImage] = useState("img/user.jpeg"); // State to hold profile image
  const [users, setUsers] = useState([]); // State to hold user data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "http://localhost/notesmafia/get_uploads.php",
          {
            method: "GET",
            credentials: "include", // Ensure cookies and credentials are included
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json(); // Parse the JSON response
        setUploads(data); // Save data to the state
        setLoading(false); // Set loading to false after data is fetched
      } catch (err) {
        setError(err.message); // Handle any errors
        setLoading(false); // Stop loading if there's an error
      }
    };

    fetchData();
  }, []); // Empty dependency array means this will run once on mount

  // Fetch user data from the API
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(
          `http://localhost/notesmafia/get_users.php`
        );
        const result = await response.json();

        if (result.status === "success") {
          const users = result.data;
          setUserData(users);
        } else {
          console.error("Failed to retrieve user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUsers();
  }, []); // Empty dependency array means this will run once on mount
  console.log("all user", userData);
  console.log("all pdf", uploads);

  useEffect(() => {
    const storedUser = localStorage.getItem("userSession");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserSession(parsedUser);
    }
  }, []);

  useEffect(() => {
    // Only fetch user data when userSession exists
    const fetchUserData = async () => {
      if (userSession) {
        try {
          const response = await fetch(
            `http://localhost/notesmafia/get_users.php`
          );
          const result = await response.json();

          if (result.status === "success") {
            const users = result.data;
            const matchedUser = users.find(
              (user) => user.id === userSession.id
            );

            if (matchedUser) {
              console.log("matchedUser", matchedUser);

              // Save user data in state
              setUserData(matchedUser);

              // Set profile image
              setPreviewImage(matchedUser.profile_image || "img/user.jpeg");
            } else {
              console.error("User not found");
            }
          } else {
            console.error("Failed to retrieve user data");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, [userSession]);

  // Conditionally log userSession if it's not null
  useEffect(() => {
    if (userSession) {
      console.log("app", userSession);
    }
  }, [userSession]);

  return (
    <GloblInfo.Provider
      value={{ userSession, setUserSession, userData, uploads, users }}
    >
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            path="/about"
            element={
              <Layout>
                <About />
              </Layout>
            }
          />
          <Route
            path="/pdf/:id"
            element={
              <Layout>
                <PdfDetails />
              </Layout>
            }
          />
          <Route
            path="/pdf/:id/edit"
            element={
              <Layout>
                <EditPdf />
              </Layout>
            }
          />
          <Route
            path="/file-upload"
            element={
              <Layout>
                <FileUpload />
              </Layout>
            }
          />
          <Route
            path="/courses"
            element={
              <Layout>
                <Courses />
              </Layout>
            }
          />
          <Route
            path="/auth/login"
            element={
              <Layout>
                <LoginSignUp />
              </Layout>
            }
          />
          <Route
            path="/auth/signup"
            element={
              <Layout>
                <LoginSignUp />
              </Layout>
            }
          />
          <Route
            path="/instructor"
            element={
              <Layout>
                <Instructor />
              </Layout>
            }
          />
          <Route
            path="/blog"
            element={
              <Layout>
                <Blog />
              </Layout>
            }
          />
          <Route
            path="/profile/:username"
            element={
              <Layout>
                <Profile />
              </Layout>
            }
          />
          <Route
            path="/profile/:username/edit"
            element={
              <Layout>
                <EditProfile />
              </Layout>
            }
          />
          <Route
            path="/single-blog"
            element={
              <Layout>
                <BlogSinglePage />
              </Layout>
            }
          />
        </Routes>
      </BrowserRouter>
    </GloblInfo.Provider>
  );
}

export default App;
